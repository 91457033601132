var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("课消统计")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-input",
            {
              staticClass: "tb-search-input",
              attrs: { placeholder: _vm.tips },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.queryDataList(1)
                },
              },
              model: {
                value: _vm.nameorcard,
                callback: function ($$v) {
                  _vm.nameorcard = $$v
                },
                expression: "nameorcard",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { slot: "prepend", placeholder: "请选择" },
                  on: { change: _vm.getSelctValue },
                  slot: "prepend",
                  model: {
                    value: _vm.select,
                    callback: function ($$v) {
                      _vm.select = $$v
                    },
                    expression: "select",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "姓名", value: "1" } }),
                  _c("el-option", { attrs: { label: "学号", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择校区" },
              on: { change: _vm.getSchoolValue },
              model: {
                value: _vm.schoolValue,
                callback: function ($$v) {
                  _vm.schoolValue = $$v
                },
                expression: "schoolValue",
              },
            },
            _vm._l(_vm.schoolArray, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择课程" },
              on: { change: _vm.getCourseValue },
              model: {
                value: _vm.classValue,
                callback: function ($$v) {
                  _vm.classValue = $$v
                },
                expression: "classValue",
              },
            },
            _vm._l(_vm.classArray, function (item) {
              return _c("el-option", {
                key: item.course_id,
                attrs: { label: item.name, value: item.course_id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择状态" },
              on: { change: _vm.getCoureState },
              model: {
                value: _vm.typeValue,
                callback: function ($$v) {
                  _vm.typeValue = $$v
                },
                expression: "typeValue",
              },
            },
            _vm._l(_vm.typeArray, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.queryDataList(1)
                },
              },
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            border: "",
            fit: "",
            "highlight-current-row": "",
            data: _vm.dataList,
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "学号", align: "center", prop: "loginName" },
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "学员校区", align: "center", prop: "campusName" },
          }),
          _c("el-table-column", {
            attrs: { label: "校管家课程", align: "center", prop: "courseName" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "总课时（小时）",
              align: "center",
              prop: "total_amount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "剩余课时（小时）",
              align: "center",
              prop: "amount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amount < 0
                      ? _c(
                          "span",
                          { style: "color" + ":" + _vm.getStateColor("异常") },
                          [_vm._v(_vm._s(scope.row.amount))]
                        )
                      : _vm._e(),
                    scope.row.amount >= 0
                      ? _c(
                          "span",
                          { style: "color" + ":" + _vm.getStateColor("正常") },
                          [_vm._v(_vm._s(scope.row.amount))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "累计课消（小时）",
              align: "center",
              prop: "cumulativeLessons",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "课消明细", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.checkDetails(scope.row)
                          },
                        },
                      },
                      [_vm._v("查 看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "课时状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.amount < 0
                      ? _c(
                          "span",
                          { style: "color" + ":" + _vm.getStateColor("异常") },
                          [_vm._v("异常")]
                        )
                      : _vm._e(),
                    scope.row.amount >= 0
                      ? _c(
                          "span",
                          { style: "color" + ":" + _vm.getStateColor("正常") },
                          [_vm._v("正常")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: this.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }