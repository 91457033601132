<template>
  <el-row class="table">
    <span class="title">课消统计</span>
    <el-row class="row-bg">
      <el-input :placeholder="tips" v-model="nameorcard" class="tb-search-input" @keyup.enter.native="queryDataList(1)">
        <el-select
          style="width: 120px;"
          v-model="select"
          slot="prepend"
          placeholder="请选择"
          @change="getSelctValue"
        >
          <el-option label="姓名" value="1"></el-option>
          <el-option label="学号" value="2"></el-option>
        </el-select>
      </el-input>
      <el-select
        v-model="schoolValue"
        placeholder="请选择校区"
        @change="getSchoolValue"
        class="tb-smselect"
      >
        <el-option v-for="item in schoolArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select
        v-model="classValue"
        placeholder="请选择课程"
        @change="getCourseValue"
        class="tb-smselect"
      >
        <el-option
          v-for="item in classArray"
          :key="item.course_id"
          :label="item.name"
          :value="item.course_id"
        ></el-option>
      </el-select>
      <el-select
        v-model="typeValue"
        placeholder="请选择状态"
        @change="getCoureState"
        class="tb-smselect"
      >
        <el-option
          v-for="item in typeArray"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="queryDataList(1)" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
    </el-row>

    <el-table
      border
      fit
      highlight-current-row
      :data="dataList"
      class="tb-list"
    >
      <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
      <el-table-column label="姓名" align="center" prop="name"></el-table-column>
      <el-table-column label="学员校区" align="center" prop="campusName"></el-table-column>
      <el-table-column label="校管家课程" align="center" prop="courseName"></el-table-column>
      <el-table-column label="总课时（小时）" align="center" prop="total_amount"></el-table-column>
      <el-table-column label="剩余课时（小时）" align="center" prop="amount">
        <template slot-scope="scope">
          <span v-if="scope.row.amount < 0" :style="'color'+':'+getStateColor('异常')">{{scope.row.amount}}</span>
          <span v-if="scope.row.amount >= 0" :style="'color'+':'+getStateColor('正常')">{{scope.row.amount}}</span>
        </template>
      </el-table-column>
      <el-table-column label="累计课消（小时）" align="center" prop="cumulativeLessons"></el-table-column>
      <el-table-column label="课消明细" align="center">
        <template slot-scope="scope">
          <el-button size="small" type="primary" plain @click="checkDetails(scope.row)">查 看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="课时状态" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.amount < 0" :style="'color'+':'+getStateColor('异常')">异常</span>
          <span v-if="scope.row.amount >= 0" :style="'color'+':'+getStateColor('正常')">正常</span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="this.pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getCourseAll, getStudentCourseHour } from '@/api/statistics'
import { getCampusAll } from '@/api/senate/class'

export default {
  data () {
    return {
      tips: '请输入姓名',
      visible: true,
      schoolValue: '',
      classValue: '',
      typeValue: '',
      schoolArray: [],
      classArray: [],
      typeArray: [
        {
          label: '正常',
          value: 1
        },
        {
          label: '异常',
          value: 2
        }
      ],
      select: '1',
      nameorcard: '',
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      dataList: [],
      course_id: '',
      campus_id: '',
      flag: '',
      loginName: '',
      name: ''
    }
  },
  created () {
    this.getAllSchoolName()
    this.getCourseAll()
    this.queryDataList(0)
  },
  methods: {
    reset () {
      this.schoolValue = ''
      this.classValue = ''
      this.typeValue = ''
      this.nameorcard = ''
      this.course_id = ''
      this.campus_id = ''
      this.flag = ''
      this.loginName = ''
      this.name = ''
      this.select = '1'
    },
    getSelctValue (val) {
      if (val === 1) {
        this.name = this.nameorcard
        this.loginName = ''
        this.tips = '请输入姓名'
      } else if (val === 2) {
        this.loginName = this.nameorcard
        this.name = ''
        this.tips = '请输入学号'
      }
    },
    getSchoolValue (val) {
      this.campus_id = val
    },
    getCourseValue (val) {
      this.course_id = val
    },
    getCoureState (val) {
      this.flag = val
    },
    getCourseAll () {
      getCourseAll().then(res => {
        if (res.state === 'success') {
          this.classArray = res.body
        }
      })
    },
    getAllSchoolName () {
      getCampusAll().then(res => {
        if (res.state === 'success') {
          this.schoolArray = res.body
        }
      })
    },
    queryDataList (val) {
      if (val === 1) {
        this.pagination.currentPage = 1
      }
      this.visible = true
      if (this.select === null) {
        this.name = this.nameorcard
        this.loginName = ''
      } else if (this.select === 1) {
        this.name = this.nameorcard
        this.loginName = ''
      } else if (this.select === 2) {
        this.loginName = this.nameorcard
        this.name = ''
      }
      getStudentCourseHour({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount: true,
        loginName: this.select === '2' ? this.nameorcard : '',
        name: this.select === '1' ? this.nameorcard : '',
        campus_id: this.campus_id,
        course_id: this.course_id,
        flag: this.flag
      }).then(res => {
        if (res.state === 'success') {
          this.visible = false
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        }
      })
    },

    // 查看明细
    checkDetails (row) {
      // console.log('checkDetails', row)
      this.$router.push({
        path: '/statistics/studentHour-detail',
        query: {
          courseID: row.course_id,
          studentID: row.student_id
        }
      })
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.queryDataList(0)
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.queryDataList(0)
    },
    getStateColor (state) {
      switch (state) {
        case '正常':
          return '#008000'
        case '异常':
          return '#FF0000'
        default:
          return '#f5c319'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
</style>
